<template>
  <div class="case-conent">
    <!--左右分屏(试题答题卡)-->
    <div class="Split-screen" v-if="paperTypes.length>0">
      <div class="lf">
        <div class="Hd">【单元实战演练】</div>
        <div class="questions-list">
          <cace-home-work :homeworks="questionData"></cace-home-work>
        </div>
      </div>
      <div class="rg">
        <div class="Hd">
          <div class="flex-lf"><el-button class="Btn-2" @click="backA()" style="border: 1px solid #F77134; background-color: #FFF; color:#F77134" type="primary" size="mini" plain round><el-icon><back /></el-icon> 返回</el-button></div>
          <div class="flex-center">学生：{{StuByUserData.stuName}}（{{StuByUserData.userName}}）</div>
          <div class="flex-rg"> <el-button class="Btn-2" type="primary" :disabled="disabled" @click="commitAnaswer()" size="mini" round>
            提交作业
          </el-button></div>
        </div>
        <div class="AnswerSheet">
          <div class="Acontent" v-for="(item,i) in formData" :key="i">
            <div class="Aname">{{item.name}}</div>
            <div class="Acn topic" v-for="(items,index) in item.list" :key="index">
              <!--选择题-->
              <div v-if="items.typeName=='选择题'">
                <div class="Alist" v-if="disabled !=1">
                  <div class="A1">{{items.questionNo}}</div>
                  <div class="A2">
                    <div class="Aoption">

                      <div class="A-tem" v-for="(Aoption,Aop) in items.option" :key="Aop"
                           @click="changeList(Aoption,Aop, items)"
                           :class="{active:answers[items.id] ==Aoption}"
                      >
                        {{Aoption}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Alist" v-else>
                  <div class="A1">{{items.questionNo}}</div>
                  <div class="A2">
                    <div class="Aoption">

                      <div class="A-tem" v-for="(Aoption,Aop) in items.option" :key="Aop"
                      >
                        {{Aoption}}
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <!--duo xuan 题-->
              <!--                       <div v-if="items.typeName=='选择题'">-->
              <!--                         <div class="Alist">-->
              <!--                           <div class="A1">{{items.questionNo}}</div>-->
              <!--                           <div class="A2">-->
              <!--                             <div class="Aoption">-->

              <!--                               <div class="A-tem" v-for="(Aoption,Aop) in items.option" :key="Aop"-->
              <!--                                    @click="changeMoreList(Aoption,Aop, items)"-->
              <!--                                    :class="{active:answers[items.id] && answers[items.id].includes(Aoption)}"-->
              <!--                               >-->
              <!--                                 {{Aoption}}-->
              <!--                               </div>-->
              <!--                             </div>-->
              <!--                           </div>-->
              <!--                         </div>-->
              <!--                       </div>-->



              <!--非选择题-->
              <div v-if="items.typeName!='选择题'">
                <div class="Alist">
                  <div class="A1" @click="AnswerPad()">{{items.questionNo}}</div>
                  <div class="A2">
                    <div class="Foption">
                      <!--                               <div class="A-tem" v-for="(Atem,i) in items.empty" :key="i"></div>-->
                      <!--                              <vue-ueditor-wrap :config="editorConfig" v-model="answers[items.id]"></vue-ueditor-wrap>-->
                      <div class="ueditorWrap" v-if="disabled==1">
                        <div class="EtorToolbarboxA">
                          <div class="Etor-01"></div>
                          <div class="Etor-02"></div>
                        </div>
                        <div class="EtorToolbarboxB"></div>
                      </div>
<!--                      <com-ueditor-wrap v-else -->
<!--                                        :questionId="items.id" -->
<!--                                        :parentId="items.parentId" -->
<!--                                        :question="questionData"  -->
<!--                                        :content.sync="answers[items.id]" -->
<!--                                        :params="{id: items.id,testPaper:1}" -->
<!--                                        @change="changeUeditor">-->
<!--                        -->
<!--                      </com-ueditor-wrap>-->

                      <div class="myeditablediv" >
                        <tinymce
                                :disabled="disabled==1"
                                v-model="answers[items.id]"
                                :questionId="items.id"
                                :parentId="items.parentId"
                                :question="questionData"
                                :content.sync="answers[items.id]"
                                :params="{id: String(items.id),testPaper:1}"
                                :stuAnswers="items"
                                :answerSheets="formData"
                                @changeStuAnswer="changeStuAnswer"
                                ref="editor1001"
                                style="height: auto; border-radius: 22px">
                        </tinymce>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
    <div class="Split-screenT" v-else-if="dlog !=null">
      <div class="kong">
        <img src="../../assets/images/kong.png">
        <p style="font-size: 22px;color: #1A86FF">请等候！数据在努力加载中...</p>
      </div>
    </div>
    <div class="Split-screenT" v-else>
      <div class="kong">
        <img src="../../assets/images/kong.png">
        <p>恭喜你！你已经做完单元卷！或无试题</p>
        <p>请点击“<span @click="toEvaluation()">知识内容测评</span>”，查看批改详情</p>
      </div>
    </div>

    <!-- 未答题提示框-->
    <el-dialog :visible.sync="AnswerDialogF" :close-on-click-modal="false" :modal-append-to-body='false'  title="提交作业" width="800px" left>
      <div class="TipM">
        <div class="Msg">
          <h2>您未答题</h2>
          <p>不能做无效操作！请答题</p>
        </div>
        <div class="Mtn">
          <el-button @click="AnswerDialogObJ()" round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" type="primary">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 您未答完题，确认提示框-->
    <el-dialog :visible.sync="AnswerDialogG" :close-on-click-modal="false" :modal-append-to-body='false'  title="提交作业" width="500px" left>
      <div class="TipM">
        <div class="Msg">
          <h2>还有试题未做</h2>
          <p>是否提交？或继续答题</p>
          <div class="R">提交后，系统将开始进行测评</div>
        </div>
        <div class="Mtn">
          <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="SubmitsaveAnaswer()" type="primary">仅保存</el-button>
          <el-button @click="SubmitHomework()" plain round  type="warning">直接提交</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 试卷已经全部做完，确认提示框-->
    <el-dialog :visible.sync="SnswerCp" :close-on-click-modal="false" :modal-append-to-body='false'  title="提交作业" width="500px" left>
      <div class="TipM">
        <div class="Msg">
          <h2>恭喜，单元卷已经全部做完！</h2>
          <p>是否进入知识内容测评</p>
        </div>
        <div class="Mtn">
          <el-button @click="back()" round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" type="primary">返回</el-button>
          <el-button @click="toEvaluation()" plain round  type="warning">查看测评</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="AnswerDialogJ" :close-on-click-modal="false" :modal-append-to-body='false'  title="提示" width="500px" left>
      <div class="TipM">
        <div class="Msg">
          <h2>还有试题未做</h2>
          <p>是否要返回上一级？</p>
        </div>
        <div class="Mtn">
          <!--                    <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="SubmitsaveAnaswerA()" type="primary">仅保存</el-button>-->
          <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="BackSubmitsave()" type="primary">确定</el-button>
          <el-button round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff" @click="gB2()" type="primary">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>




</template>


<script>
  import { mapState } from 'vuex'
  import caceHomeWork from "../../components/caceHomeWorkA";
  import tinymce from "../../components/tinymce/index";
 // import ComUeditorWrap from "../../components/editorStu/ComUeditorWrap";
  import {studyXlaObj,commitAnaswerObj, saveAnaswerObj} from '@/api/details'   //路径
  export default {
    name: '',
    inject: ['reload'],
    data() {
      return {
        disabled:0,
        AnswerDialogJ:false,
        AnswerDialogF:false,//未答题提示框
        AnswerDialogG:false,//您未答完题，确认提交
        AnswerPadDialog:false,//答题写字板弹框
        SnswerCp:false,
        value: null,
        NodesId:null,
        likeTopicData:{},
        likeT:0,

        currentPage:null,
        pageSize:null,
        total:null,
        picImgSrc: {},


        options:{
          isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
          lastWriteWidth: 2,  //下笔的宽度 [Number] 可选
          lineCap: 'round',   //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]	正方形线帽
          lineJoin: 'round',  //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
          canvasWidth: 1200, //canvas宽高 [Number] 可选
          canvasHeight: 600,  //高度  [Number] 可选
          isShowBorder: true, //是否显示边框 [可选]   当签名模式处于false的时候此选项才生效
          bgColor: '#E8F7FE', //背景色 [String] 可选
          borderWidth: 1, // 网格线宽度  [Number] 可选
          borderColor: "#AFE6FF", //网格颜色  [String] 可选
          writeWidth: 2, //基础轨迹宽度  [Number] 可选
          maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
          minWriteWidth: 2, // 写字模式最小线宽  [Number] 可选
          writeColor: '#101010', // 轨迹颜色  [String] 可选
          imgType:'png'   //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
        },
        dlog:null,

        le2:null,
        cur: 0, //默认选中第一个tab
        repA:0,
        index:0,
        isLike3:null,
        topicId2:null,
        detailQus:1,
        NoBuy:null,
        editorConfig:{
          // 你的UEditor资源存放的路径,相对于打包后的index.html
          //UEDITOR_HOME_URL: "/UEditor/",
          UEDITOR_HOME_URL: '/ueditor/',
          serverUrl: process.env.VUE_APP_BASE_URL + "/study/baiduEditor",
          // 编辑器不自动被内容撑高
          autoHeightEnabled: false,
          // 初始容器高度
          initialFrameHeight: 300,
          // 初始容器宽度
          initialFrameWidth: "100%",
          // 关闭自动保存
          enableAutoSave: false,
          // 自定义工具栏，需要额外选项可以参考ueditor.config.js
        },
        type: 1,
        content: '',
        answers: {}, // 选择题答题内容
        isactive: false,
        imGurl:'',
        paperTypes:[],
        questionData:null,// 试题
        formData:[],// 答题卡
        current:null,
        currentNum:'',
        cid:null,
        isshowImg:false,
        queryResData:{},
        IwantQuestion:false,//我要提问-弹框
        TcReplyLData:[],//论坛主题回复列表
        level:null,
        query:{
          id:null
        },
        photoId:'',
        prolisFrom:{
          replyMsg:''
        },
        QuestionFrom:{
          topic:''
        },
        showQuestion: true,//展开收起
        indexPic: 0,
        heightpx: '320px',

        picList:[

        ],
        picListCarousel:[],


        currentIndex: 0, // 选项卡当前的索引
        Unitname:'',
      }
    },
    created() {

      this.EducationCase = this.$route.query.EducationCase;
      this.clickFlag=this.$route.query.clickFlag;
      this.bookId = this.$route.query.bookId;
      this.folderId = this.$route.query.folderId;
      this.id = this.$route.query.id;
      this.NoBuy= this.$route.query.NoBuy;
      this.stu = this.$route.query.stu;
      this.disabled = this.$route.query.disabled;
      this.token= localStorage.getItem('Authorization')
      this.queryRes()
      this.queryStudyNodes()
      this.timeObj()
      this.photoBJ()
      this.portraitList.forEach((ele) => {
        if (this.replyTopicBData.photo == ele.id){
          this.picImgSrc = ele
        }
      })


    },
    mounted() {


    },
    components: {
      caceHomeWork,
      tinymce,
     // ComUeditorWrap,
      //VueUeditorWrap
    },
    methods: {
      //返回
      backA() {
        // if(this.ruleForm.note1.length !=this.queryStudyNodesData.note1.length && this.ruleForm.note4.length !=this.queryStudyNodesData.note4.length&& this.ruleForm.note7.length !=this.queryStudyNodesData.note7.length ){
        //   console.log('学历案笔记1有变化')
        //   // this.saveStudyNodesForm()
        //   this.saveStudyNodesForm('ruleForm')
        // }

        let questionIdList = Object.keys(this.answers)
        let values = Object.values(this.answers)
        let isNull = false //是否有未答得题目
        //console.log(questionIdList.length ,this.quesSum,'shit')

        let answerList = questionIdList.map(item => {
          if (!this.answers[item] && values.some(it => it !== '')) {
            isNull = true
          }
          return {
            questionId: Number(item),
            answer: this.answers[item]
          }
        })
        if(values.every(it => it === '')) {
          // 您未答题提醒
          console.log('未答题')
          this.ToCatalogue()
          this.SubmitsaveAnaswer()


        }else if(isNull){
          // 您未答完题，确认提交吗
          console.log('mei答题')
          this.AnswerDialogJ=true
        }else {
          console.log('已经答完题，再次')
          this.SubmitsaveAnaswerBack()
          this.ToCatalogue()
          // let obj = {
          //   answer: this.answers,
          //   questionId:this.questionId
          // }
          //this.SubmitHomework()
        }
      },
      back() {
        this.$router.go(-1);
      },
      // 关闭未答题框
      AnswerDialogObJ() {
        this.AnswerDialogF=false
      },
      photoBJ(){
        this.photoId = this.replyTopicBData.photo;
        console.log(this.photoId,'this.photoId')
      },
      alwaysA() {
        this.always=1
        this.bottomShow = !this.bottomShow;
        if(this.bottomShow){
          this.alwaysName = '播放'
        }else{
          this.alwaysName = '暂停'
        }
      },
      alwaysB() {
        this.always=2
      },
      changeUeditor (val, params) {
        this.$set(this.answers, params.id, val)

      },

      changeStuAnswer(stuAnswer) {

        if (this.formData) {
          for (let i = 0 ; i < this.formData.length; i ++) {
            let item = this.formData[i];
            let isFind = 0;
            for (let j = 0 ; j < item.list.length; j ++) {
              let qu = item.list[j];
              let quId = qu.id;
              if (quId == stuAnswer.id) {
                qu.canvas = stuAnswer.canvas;
                isFind = 1;
                break;
              }
            }
            if (isFind == 1) {
              break;
            }
          }
        }

      },

      /**
       * 清除画板
       */
      canvasClear(){
        this.$refs.SignCanvas.canvasClear();
      },

      /**
       * 保存图片
       */
      saveAsImg(){
        const img = this.$refs.SignCanvas.saveAsImg();
        alert(`image 的base64：${img}`);
      },

      /**
       * 下载图片
       */
      downloadSignImg(){
        this.$refs.SignCanvas.downloadSignImg();
      },
      AnswerPad() {
        this.AnswerPadDialog = true
      },

      timeObj() {
        this.query.id = this.timeData[3].id
        this.interval = this.timeData[3].name
      },

      changeCarousel (count) {
        this.$refs['scroll'].wrap.scrollTop = count == 0 ? 0 : 98 * count - 50 //想滚到哪个高度，就写多少
        this.indexPic = count
      },
      reset () {
        this.answers = {}
      },
      getEditorValue (value, q) {
        console.log(value, q, 'value, q')
      },



      // exportStudyNodes() {
      //   let obj = {
      //     resourceId: this.id,
      //   }
      //   exportStudyNodesObj(obj).then((res) => { // eslint-disable-line no-unused-vars
      //     console.log(res,'900000')
      //     let blob = new Blob([res.data],{ type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8'});
      //     let objectUrl = URL.createObjectURL(blob);
      //     window.location.href = objectUrl;
      //   })
      // },
      //

      CloeSubmitHomework() {
        this.AnswerDialogG=false
      },
      // 仅保存
      SubmitsaveAnaswer() {
        var paperId = this.paperId
        let questionIdList = Object.keys(this.answers)
        let answerList = questionIdList.map(item => {
          return {
            questionId: Number(item),
            answer: this.answers[item]
          }
        })
        saveAnaswerObj(answerList,paperId).then((res) => { // eslint-disable-line no-unused-vars
          this.AnswerDialogG=false
          this.queryRes()

        })

      },
      //轮播图和试题
      queryRes() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)'
        });
        this.dlog= 1
        this.answers = {}
        let obj = {
          bookId: this.bookId,
          folderId: this.folderId,
          id: 0,
          type:1
        }
        studyXlaObj(obj).then((res) => { // eslint-disable-line no-unused-vars
          if(res.code==200) {
            this.dlog= null
            loading.close();
            this.paperTypes = res.data.paperTypes
            this.Unitname = res.data.qus[0].name
            this.paperId = res.data.qus[0].id
            this.questionId = res.data.qus[0].id
            this.type = res.data.qus[0].type
            this.questionData = res.data.qus[0].question
            this.formData = res.data.qus[0].answerSheet
            res.data.qus[0].answerSheet.map(item => {
              if (item.list != null) {
                item.list.map(it => {
                  if (it.isDiscourse == 1) {
                    if (it.contentMap.discourseList != null) {
                      it.contentMap.discourseList.map(i => {
                        // i.content = JSON.parse(i.content)
                        // console.log(i, '9090')
                        this.$set(this.answers, i.id, i.studentAnswer)
                      })
                    }
                  } else {
                    // it.content = JSON.parse(it.content)
                    // console.log(it, '8888')
                    this.$set(this.answers, it.id, it.studentAnswer)
                  }
                })
              }
            })

            let questionsSum = 0;
            this.formData.forEach((item) => {
              //遍历prodAllPrice这个字段，并累加
              questionsSum += item.list.length;
            });
            //返回
            this.quesSum = questionsSum;
            console.log(this.quesSum,'数量')

            this.$nextTick(() => {
              this.setActiveItem(this.indexPic)
            })
          } else {
            this.formData = null
            this.questionData=null
          }


        })
      },


      changeList(items,Aop, parent){
        this.currentNum = parent.questionNo
        this.current = Aop;//this指向app      :class="{active:Aop==current}
        this.cid = items.id;//this指向app
        this.$set(this.answers, parent.id, items)
        // this.answers[parent.id] = items
        console.log(this.answers, '选中的选项')

      },
      changeMoreList(items,Aop, parent){
        this.currentNum = parent.questionNo
        this.current = Aop;//this指向app      :class="{active:Aop==current}
        this.cid = items.id;//this指向app
        if (!this.answers[parent.id]) {
          this.$set(this.answers, parent.id, [])
        }
        let idx = this.answers[parent.id].findIndex(it => it === items)
        if (idx == -1) {
          this.answers[parent.id].push(items)
        } else {
          this.answers[parent.id].splice(idx, 1)
        }
        console.log(this.answers, '选中的选项')

      },
      //提交作业
      commitAnaswer() {



        this.paperTypes.forEach((item,index)=>{
          if(item==1){
            console.log(item,'900')
          }
        })
        var paperId = this.paperId
        console.log(this.formData,'8888888888')
        console.log(this.answers,'shit')
        let questionIdList = Object.keys(this.answers)
        let isNull = false //是否有未答得题目
        console.log(questionIdList.length ,this.quesSum,'shit')

        let answerList = questionIdList.map(item => {
          if (!this.answers[item]) {
            isNull = true
          }
          return {
            questionId: Number(item),
            answer: this.answers[item]
          }
        })
        // 未答题提示
        if(questionIdList=='') {
          // 您未答题提醒
          this.AnswerDialogF=true
        }else if(isNull){
          // 您未答完题，确认提交吗
          this.AnswerDialogG=true
        }else {
          // let obj = {
          //   answer: this.answers,
          //   questionId:this.questionId
          // }
          this.SubmitHomework()
        }

      },
      SubmitHomework() {
        this.paperTypes.forEach((item,index)=>{
          if(item==1){
            console.log(item,'900')
          }
        })
        var paperId = this.paperId
        let questionIdList = Object.keys(this.answers)
        let answerList = questionIdList.map(item => {
          return {
            questionId: Number(item),
            answer: this.answers[item]
          }
        })
        commitAnaswerObj(answerList,paperId).then((res) => { // eslint-disable-line no-unused-vars
          if(res.code==200) {
            this.AnswerDialogG=false
            this.SnswerCp=true
            // this.$router.push({
            //   path: "/mainb/evaluation",
            //   query: {
            //     EducationCase:this.EducationCase,
            //     stu:this.stu,
            //     clickFlag:2
            //   }
            // });
          } else {
            this.$message.error(res.message);
          }


        })
      },


      // setActiveItem (i) {
      //   if (this.$refs.carousel) {
      //     this.$refs.carousel.setActiveItem(i)
      //   }
      // },
      // changeCarousel (e) {
      //   this.$refs['scroll'].wrap.scrollTop = 120  //想滚到哪个高度，就写多少
      //   this.indexPic = e
      //
      // },
      // clickItem (index) {
      //   this.indexPic = index
      //   this.setActiveItem(index)
      // },
      ingObj(item) {
        console.log(item.id,'8888')
        this.imGurl = item.base64
        this.isshowImg = true
      },


      setActiveItem (i) {
        console.log(i,"ccccccccccccccc")
        if (this.$refs.carousel) {
          this.$refs.carousel.setActiveItem(i)
        }
      },

      clickItem (item, index) {
        // console.log(item.uid,"dddddddddddddddddd")
        this.indexPic = item.uid
        // let apicDom = document.getElementsByClassName('pic-item')[0]
        // console.log(apicDom.scrollHeight, this.$refs.scroll.$refs.wrap.scrollTop)
        // let domScrollTop = apicDom * index
        // if (domScrollTop > this.$refs.scroll.$refs.wrap.scrollHeight) {
        //   domScrollTop = this.$refs.scroll.$refs.wrap.scrollHeight
        // }
        // this.$refs.scroll.$refs.wrap.scrollTop = domScrollTop
        // console.log(this.$refs.scroll.$refs.wrap.scrollTop)

        this.setActiveItem(item.uid)
      },
      tabChange(val) {
        this.activeIndex = val;
        this.$router.push({   //新增一个参数
          query:{tabsid:val}
        });

      },
      BackSubmitsave() {
        // 保存
        this.SubmitsaveAnaswer()
        // 跳转到目录
        this.ToCatalogue()
      },
      gB2() {
        this.AnswerDialogJ=false
      },
      // 跳转到目录
      ToCatalogue() {
        this.$router.push({
          path: "/mainb/catalogue",
          query: {
            EducationCase: this.EducationCase,
            clickFlag: this.clickFlag,
            bookId: this.bookId,
            stu: this.stu,
            totalPrice:this.totalPrice,
            totalDiscount:this.totalDiscount,
          }
        });
      },
      //查询列表
      listObj() {
        this.listData = this.list
      },
      //我要提问-弹框
      IwantQuestionObj() {
        this.IwantQuestion = true
      },
      //去知识内容测评
      toEvaluation() {
        this.$router.push({
          path: "/mainb/evaluation",
          query: {
            EducationCase:this.EducationCase,
            stu:this.stu,
            clickFlag:2
          }
        });
      },

    },
    computed:{
      ...mapState([
        'questionType',
        'StuByUserData'
      ])
    },
    watch: {
      questionType: {
        handler: function() {
          this.queryRes()
        },
      }
    }

  }
</script>
<style lang="scss" scoped>

  .case-conent {
    width: 98%;
    margin: 20px auto;
    background-color: transparent;
    border: 0px solid #DDDDDD;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.16);
    opacity: 0.9;
    .Btn-2 {
      width: 70px;
      font-size: 12px;
      height: 28px;
      padding: 0;
      vertical-align: middle;
      margin-top: -2px;
    }
    /*左右分屏(试题答题卡)*/
    .Split-screen {
      width: 100%;
      margin: auto;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .lf {
        width: 50%;
        background-color: #FFF;
        box-shadow: 0px 0px 2px rgba(255, 104, 32, 0.3);
        justify-content: flex-start;
        position: relative;

        .Hd {
          width: 100%;
          height: 36px;
          background-color: #37AEFF;
          line-height: 36px;
          text-align: center;
          font-size: 16px;
          color: #fff;
          margin-bottom: 10px;
        }
        .questions-list {
          overflow-y: auto;
          height: 94%;
        }

      }

      .rg {
        width: 49%;
        background-color: #FFF;
        box-shadow: 0px 0px 2px rgba(255, 104, 32, 0.3);
        box-sizing: border-box;
        justify-content: flex-end;
        position: relative;

        .Hd {
          width: 100%;
          height: 36px;
          background-color: #fff;
          border-bottom:1px dashed #FF6820;
          padding: 6px 10px;
          box-sizing: border-box;
          font-size: 14px;
          margin: auto auto 10px auto;
          position: absolute;
          display: flex;
          .flex-lf {
            width: 20%;
            justify-content: flex-start;
          }
          .flex-center {
            width: 60%;
            justify-content: center;
          }
          .flex-rg {
            width: 20%;
            text-align: right;
            justify-content: flex-end;
          }
        }
        .AnswerSheet {
          width: 96%;
          height: 94%;
          overflow-y: auto;
          box-sizing: border-box;
          margin: 40px auto auto auto;

          .Acontent {
            width: 100%;
            padding: 8px 20px;
            box-sizing: border-box;
            display: grid;
            .Aname {
              line-height: 40px;
              font-size: 14px;
              font-weight: bold;
            }
            .Acn {
              padding:  0px 0px;
              box-sizing: border-box;
            }
            .Alist {
              width: 100%;
              margin: 10px 0px;
              padding: 5px 0px;
              box-sizing: border-box;
              position: relative;
              .A1 {
                width: 40px;
                height: 24px;
                line-height: 24px;
                text-align: right;
                box-sizing: border-box;
                padding-right: 10px;
                float: left;
                position: absolute;
              }
              .A2 {
                width: 100%;
                padding-left: 50px;
                box-sizing: border-box;
                display: flex;
                float: left;
                /*选项*/
                .Aoption {
                  width: 70%;
                  display: flex;
                  .A-tem {
                    width: 40px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    border: 1px solid #707070;
                    margin: 0 10px;
                  }

                }
                /*是否正确*/
                .correct-Box {
                  width: 30%;
                  img {
                    margin-top: 5px;
                  }
                }
                /*非选项*/
                .Foption {
                  width: 100%;
                  .A-tem {
                    width: 100%;
                    height: 30px;
                    border-bottom: 1px solid #303133;
                    margin: 0 10px;
                  }
                }
              }
              .A3 {
                width: 500px;
                float: left;
                .D1 {
                  width: 100%;
                  font-size: 14px;
                  box-sizing: border-box;
                  padding-left: 40px;
                  display: flex;
                  .D1-A1 {
                    width: 65px;
                    height: 24px;
                    line-height: 24px;
                  }
                  .D1-A2 {
                    line-height: 24px;
                    width: 390px;
                  }
                }
              }

            }
            .Blist {
              width: 100%;
              margin: 10px 0px;
              padding: 5px 0px;
              box-sizing: border-box;
              position: relative;
              .A1 {
                width: 30px;
                height: 24px;
                line-height: 24px;
                text-align: right;
                box-sizing: border-box;
                padding-right: 10px;
                float: left;
                position: absolute;
              }
              .A2 {
                width: 500px;
                padding-left: 40px;
                box-sizing: border-box;
                display: flex;
                float: left;
                .Da-1 {
                  width: 90%;
                  margin-top: 0px;
                  padding: 0 10px;
                  box-sizing: border-box;
                  background-color: #FBF8D9;
                  line-height: 24px;
                  border: 1px solid #e5e9f2;
                }
                .Da-2 {
                  width: 90%;
                  margin-top: 0px;
                  padding: 0 10px;
                  box-sizing: border-box;
                  line-height: 24px;
                  ::v-deep.AoptionB {
                    width: 100%;
                    display: flex;
                    p {
                      img {
                        width: 100%;
                        border: 1px solid #E5E5E5;
                      }
                    }
                  }
                }
                /*选项*/

                /*是否正确*/
                .correct-Box {
                  width: 30%;
                  img {
                    margin-top: 5px;
                  }
                }
                /*非选项*/
                .Foption {
                  width: 100%;
                  .A-tem {
                    width: 100%;
                    height: 30px;
                    border-bottom: 1px solid #303133;
                    margin: 0 10px;
                  }
                }

              }
              .A3 {
                width: 500px;
                float: left;
                .D1 {
                  width: 100%;
                  font-size: 14px;
                  box-sizing: border-box;
                  padding-left: 50px;
                  display: flex;
                  .D1-A1 {
                    width: 40px;
                    height: 30px;
                    line-height: 30px;
                  }
                  .D1-A2 {
                    line-height: 30px;
                    width: 390px;
                  }
                }
              }

            }

          }

        }


      }
    }
    .Split-screenT {
      width: 100%;
      background: #fff;
      height: 780px;
      margin: auto;
      display: flex;
      justify-content: center;
      .kong {
        width: 80%;
        margin: auto;
        text-align: center;
        img {
          margin: auto;
          height: 200px;
        }
        p {
          height: 30px;
          line-height: 30px;
          color: #c6c6c6;
          span {
            color: #238bff;
            cursor:pointer
          }
        }
      }
    }



  }
  .kong {
    width: 400px;
    margin: auto;
    text-align: center;
    img {
      margin: auto;
      height: 200px;
    }
    p {
      height: 30px;
      line-height: 30px;
      color: #c6c6c6;
      span {
        color: #238bff;
        cursor:pointer
      }
    }
  }
  // 作业提交提示
  .TipM {
    width: 96%;
    margin: auto;
    .Msg {
      width: 100%;
      text-align: center;
      h2 {
        font-size: 20px;
        color: #FF4D00;
      }
      p {
        font-size: 14px;
      }
      .R {
        color: #999;
        font-size: 14px;
        padding-top: 20px;
      }
    }
    .Mtn {
      width: 100%;
      margin-top: 20px;
      text-align: center;

    }
  }
  .ueditorWrap{
    width: 100%;
    border: 1px solid #d4d4d4;
    background-color: #fff;
    position: relative;
    overflow: visible;
    border-radius: 4px;
    .EtorToolbarboxA {
      width: 100%;
      height: 40px;
      position: relative;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      border-bottom: 1px solid #d4d4d4;
      border-radius: 4px 4px 0 0;
      box-shadow: 0 1px 4px rgb(0 0 0 / 7%);
      .Etor-01 {
        width: 60px;
        height: 40px;
        float: left;
        justify-content: flex-start;
        background: url("../../assets/images/Case-10.png") no-repeat 15px 7px;
        background-size:16px 16px;
      }
      .Etor-02 {
        width: 40px;
        height: 40px;
        justify-content: flex-end;
        float: right;
        background: url("../../assets/images/Case-11.png") no-repeat 15px 7px;
        background-size:16px 16px;
      }

    }
    .EtorToolbarboxB {
      height: 300px;



    }
  }























  .edu-container-detal {
    width: 100%;
    padding: 0px 20px 20px 0px;
    box-sizing: border-box;

    margin-bottom: 20px!important;
    .case-conent-detal {
      width: 90%;
      margin: 20px auto;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 0.9;
      // 第一块
      .details-A {
        width: 98%;
        margin: auto;
        display: flex;
        .area-lf {
          width: 75%;
          background-color: #F9F9F9;
          float: left;
          position: relative;



          .col_l_main {
            width: 100%;
            padding: 10px 10px 10px 0px;
            box-sizing: border-box;
            overflow-y: auto;
            //::v-deep .el-scrollbar__wrap {
            //  overflow-x: hidden;
            //}
            // 轮播图片区域
            .focus_PiC {
              height: 450px;
              .scrollList{
                width:100%;
                display: flex;
                .Pic-left{
                  width:250px;
                  height: 400px;
                  justify-content: flex-start;
                  .scrollbar{
                  }
                  ::v-deep.el-scrollbar {
                    //height: 100%;
                    height: 400px;
                  }
                  ::v-deep.el-scrollbar__wrap {
                    //overflow: hidden;
                    //width: 100%;
                    //height: 120%;
                  }
                }
                .Pic-right {
                  width: 90%;
                  ::v-deep.el-carousel__container {
                    height: 400px;
                    text-align: center;
                  }
                  ::v-deep.el-carousel__item {
                    background: url("../../assets/images/pic-bj.png") no-repeat;
                    background-size: cover;
                  }
                  img{

                    height: 400px;
                    margin: auto;
                    justify-content: center;
                  }
                }
              }
            }
            .blackName1 {
              width: 98%;
              height: 46px;
              margin:auto;
              box-sizing: border-box;
              border-bottom: 1px solid #f1f1f1;
              display: flex;

              .lf {
                width: 40%;
                font-size: 16px;
                justify-content: flex-start;

                img {
                  vertical-align: middle;
                  margin-top: -2px;
                }
              }
              .bc{
                width: 20%;
                justify-content: center;
                display: flex;
                .dtY {
                  height: 28px;
                  padding: 4px 10px;
                  background: rgba(241, 241, 241, 0.39);
                  border: 1px solid #E9E9E9;
                  opacity: 1;
                  border-radius: 30px;
                  position: relative;
                  .Btn-1 {
                    width: 60px
                  }
                  .Btn-2 {
                    width: 70px;
                    font-size: 12px;
                    height: 28px;
                    padding: 0;
                    vertical-align: middle;
                    margin-top: -2px;
                  }
                  ::v-deep.el-input__inner {
                    height: 28px;
                    line-height: 28px;
                  }
                  ::v-deep.el-select .el-input .el-select__caret {
                    color: #C0C4CC;
                    font-size: 14px;
                    margin-top: 6px;
                  }
                }
              }

              .rg {
                width: 40%;
                justify-content: flex-end;
                display: flex;
                text-align: right;

                .k {
                  padding-left: 30px;
                  background: #06A8C9 url("../../assets/images/Case-02.png") no-repeat 7px 5px;
                  color: #fff;
                  border: 1px solid #06A8C9;

                }
              }
            }
            .blackName2 {
              width: 100%;
              height: 34px;
              line-height: 24px;
              margin-bottom: 10px;
              box-sizing: border-box;
              border-bottom: 1px solid #f1f1f1;
              display: flex;

              .lf {
                width: 50%;
                font-size: 16px;

                img {
                  vertical-align: middle;
                  margin-top: -2px;
                }
              }

              .rg {
                width: 50%;
                display: flex;
                justify-content: flex-end;
                text-align: right;

                .k {
                  padding-left: 30px;
                  background: #06A8C9 url("../../assets/images/Case-02.png") no-repeat 7px 5px;
                  color: #fff;
                  border: 1px solid #06A8C9;

                }
              }
            }
            // 试题与答题卡
            .focus_main2 {
              height: 800px;
              border: solid 1px #ccc;
              display: flex;
              .kong {
                width: 400px;
                margin: auto;
                text-align: center;
                img {
                  margin: auto;
                  height: 200px;
                }
                p {
                  height: 30px;
                  line-height: 30px;
                  color: #c6c6c6;
                  span {
                    color: #238bff;
                  }
                }
              }
              .st {
                width: 50%;
                height: 790px;
                overflow-y: auto;
              }
              .dt {
                width: 50%;
                height: 790px;
                overflow-y: auto;
              }

            }
          }

          /*操作按钮*/
          .Btn {
            width: 14px;
            height: 60px;
            margin-top: -480px;
            position: absolute;
            margin-left: 100%;
            opacity: 1;

            .BtnA {
              width: 14px;
              height: 60px;
              background: url("../../assets/images/arrow-right.png") no-repeat;
              background-size: cover;
              color: #007B94;
              cursor: pointer;
              padding-top: 20px;
              padding-right: 6px;
              position: absolute;
              margin-left: -14px;
              box-sizing: border-box;
              border-radius: 5px 0px 0px 5px;

              z-index: 99;
            }

            .BtnB {
              width: 14px;
              height: 60px;
              background: url("../../assets/images/arrow-left.png") no-repeat;
              background-size: cover;
              color: #007B94;
              cursor: pointer;
              padding-top: 20px;
              padding-right: 10px;
              box-sizing: border-box;
              border-radius: 0px;
              z-index: 99;
            }
          }
        }
        .area-rg {
          width: 25%;
          //background: #E7F1F3;
          box-sizing: border-box;
          float: right;

          .blackName {
            width: 100%;
            height: 30px;
            line-height: 24px;
            font-weight: bold;
            margin-bottom: 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #E5E5E5;
            font-size: 14px;

            img {
              vertical-align: middle;
              margin-top: -2px;
            }
          }

          .bick-r-01 {
            width: 100%;
            height: 74px;
            background-color: #238bff;
            margin-bottom: 10px;

            img {
              width: 100%;
              height: 74px;
            }
          }

          .bick-r-02 {
            width: 100%;
            height: 1280px;
            padding: 10px;
            box-sizing: border-box;
            border-top: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-bottom: 0px solid #ccc;
            border-left: 1px solid #ccc;
            background-color: #FBF8D9;

            .note {
              width: 100%;
              height: 1180px;
              overflow-y: auto;
              box-sizing: border-box;
              margin: auto;
              .notecase {
                width: 100%;
                margin-bottom: 20px;
                .cName {
                  width: 120px;
                  height: 24px;
                  box-sizing: border-box;
                  padding-left: 20px;
                  background-color: #FF9100;
                  color: #fff;
                  line-height: 24px;
                }
                .cList {
                  width: 100%;
                  .list {
                    width: 100%;
                    display: flex;
                    position: relative;
                    .LA {
                      width: 10px;
                      height: 24px;
                      box-sizing: border-box;

                      padding-top: 18px;
                      .yd {
                        width: 3px;
                        height: 3px;
                        background-color: #238bff;
                        border-radius: 50px;
                      }
                    }
                    .LB {
                      width: 100%;
                      padding-top: 6px;
                      border-bottom: 1px solid #E5E5E5;
                      font-size: 25px;
                      .Ob{
                        height: 70px;
                        overflow-y: auto;
                      }
                      ::v-deep.el-input__inner {
                        width: 100%;
                        background-color: #FBF8D9;
                        height: auto;
                        border-radius: 0px;
                        border-top-width: 0px;
                        border-left-width: 0px;
                        border-right-width: 0px;
                        border-bottom-width: 0px;
                        /*outline: medium;*/
                      }
                      ::v-deep.el-textarea__inner {
                        display: block;
                        resize: vertical;
                        padding: 0px 15px;
                        line-height: 20px;

                        box-sizing: border-box;
                        width: 100%;
                        font-size: inherit;
                        color: #606266;
                        background-color: #FBF8D9;
                        background-image: none;
                        border-top-width: 0px;
                        border-left-width: 0px;
                        border-right-width: 0px;
                        border-bottom: 0px solid #E5E5E5;
                        border-radius: 0px;
                        transition: none;
                      }
                      #textarea {
                        width: 280px;
                        background-color: #FBF8D9;
                        height: auto;
                        line-height: 24px;
                        border-radius: 0px;
                        border-top-width: 0px;
                        border-left-width: 0px;
                        border-right-width: 0px;
                        border-bottom-width: 1px;
                        /*outline: medium;*/
                      }

                    }
                  }
                }
              }
            }

            .noteBTn {
              width: 100%;
              height: 38px;
              padding: 5px 0px 12px 10px;
              box-sizing: border-box;
              text-align: center;
              margin: auto;
              display: flex;
              justify-content: center;
            }
          }

        }
      }

      .answering {
        width: 98%;
        height: 800px;
        border: 1px solid #ccc;
        margin: auto auto 80px auto;
        background-color: #fff;

        .blackName1 {
          width: 98%;
          height: 34px;
          line-height: 24px;
          margin: 10px auto;
          box-sizing: border-box;
          border-bottom: 1px solid #f1f1f1;
          display: flex;

          .lf {
            width: 50%;
            font-size: 16px;
            justify-content: flex-start;

            img {
              vertical-align: middle;
              margin-top: -2px;
            }
          }


          .rg {
            width: 50%;
            justify-content: flex-end;
            display: flex;
            justify-content: flex-end;
            text-align: right;

            .k {
              padding-left: 30px;
              background: #06A8C9 url("../../assets/images/Case-02.png") no-repeat 7px 5px;
              color: #fff;
              border: 1px solid #06A8C9;

            }
          }
        }
        .answeringQuestion {
          width: 98%;
          height: 680px;
          margin: 10px auto;
          border: solid 1px #ccc;
          display: flex;
          .answering-lf {
            width: 30%;
            height: 650px;
            overflow-y: auto;
            .prolist {
              box-sizing: border-box;
              padding: 10px 0 0 0;
              float: left;
              width: 100%;
              padding-bottom: 6px;
              padding-left: 10px;
              box-sizing: border-box;
              border-right: 1px solid #ddd;
              border-bottom: 1px solid #ddd;
              list-style: none;
              text-align: left;
              .ansBtnA {
                height: 24px;
                padding: 0px 10px;
              }
              .pl01 {
                width: 96%;
                height: 50px;
                line-height: 20px;
                margin: auto;
                display: flex;
                .pA {
                  width: 70%;

                }
                .pB {
                  width: 30%;
                  text-align: right;
                }
              }
              .pl02 {
                width: 96%;
                height: 36px;
                line-height: 36px;
                margin: auto;
                display: flex;
                .pA {
                  width: 20%;
                  justify-content: flex-start;
                  color: #999;
                }
                .pB {
                  width: 40%;
                  justify-content: center;
                  color: #999;
                }
                .pC {
                  width: 40%;
                  justify-content: flex-end;
                  color: #1A86FF;
                  text-align: right;
                }
              }
            }
            .activeAnswering {
              color: #0090DA;
              border-left: 4px solid #1A86FF;
              border-right: 1px solid #E8F7FE;
              background: #E8F7FE;
              opacity: 1;
              .pl01 {
                width: 96%;
                line-height: 20px;
                margin: auto;
                display: flex;
                .pA {
                  width: 70%;
                  font-size: 16px;
                  color: #0090DA;
                  font-weight: bold;
                }
                .pB {
                  width: 30%;
                  text-align: right;
                }
              }
            }
          }
          .answering-rg {
            width: 70%;
            background-color: #E8F7FE;
            /* 聊天列表 */
            .lt-inf {
              padding: 0 10px;
              box-sizing: border-box;
              height: 560px;
              margin: auto;
              overflow-y: auto;
              .ansBtnB {
                height: 24px;
                padding: 0px 10px;
              }
            }
            /* 聊天回复 */
          }
        }
        .focus_main6 {
          width: 98%;
          height: 730px;
          margin: 10px auto;
          border: solid 1px #ccc;
          position: relative;
          .tab {
            width: 100%;
            position: absolute;
            display: flex;
            // zuob
            .problem {
              width: 30%;
              overflow: hidden;
              padding: 0;
              margin: 0;
              float: left;
              .prolist {
                box-sizing: border-box;
                padding: 10px 0 0 0;
                float: left;
                width: 100%;
                padding-bottom: 6px;
                padding-left: 10px;
                box-sizing: border-box;
                list-style: none;
                text-align: left;
                .pl01 {
                  width: 96%;
                  line-height: 20px;
                  margin: auto;
                  display: flex;
                  .pA {
                    width: 70%;
                  }
                  .pB {
                    width: 30%;
                    text-align: right;
                  }
                }
                .pl02 {
                  width: 96%;
                  height: 36px;
                  line-height: 36px;
                  margin: auto;
                  display: flex;
                  .pA {
                    width: 33%;
                    justify-content: flex-start;
                    color: #999;
                  }
                  .pB {
                    width: 40%;
                    justify-content: center;
                    color: #999;
                  }
                  .pC {
                    width: 26%;
                    justify-content: flex-end;
                    color: #1A86FF;
                    text-align: right;
                  }
                }
              }
              .activef {
                border-left: 3px solid #1A86FF;
                background-color: #E8F7FE;
                color:#1A86FF ;
              }
            }
            .prolistHd {
              width: 1300px;
              height: 729px;
              display: none;
              background-color: #E8F7FE;
              border-top: 0px;


            }
            .current {
              display: block;
              /* 聊天列表 */
              .lt-inf {
                padding: 0 10px;
                box-sizing: border-box;
                height: 620px;
                margin: auto;
                overflow-y: scroll;
              }

            }

          }

        }
      }
      /* 聊天发布 */
      .lt-fb {
        padding: 0 10px;
        box-sizing: border-box;
        height: 100px;
        margin: auto;
        display: flex;
        .pA {
          width: 90%;
          justify-content: flex-start;
        }
        .pB {
          width: 10%;
          text-align: right;
          justify-content: flex-end;
        }
      }



    }
  }
  .addclass {
    color: red;
  }
  .CaseMenu {
    line-height: 40px;
    cursor:pointer;
    border-left: 1px solid #84c8ed;
    border-right: 1px solid #84c8ed;
    text-align: center;
    color: #fff;
  }
  .active {
    color: #fff;
    background: #0055C5 ;
    opacity: 1;
  }
  .edu-container {
    margin: auto;
  }







  .intlist {
    width: 98%;
    margin: auto;
    padding: 10px 0;
    display: flex;
    border-bottom: 1px solid #AFE6FF;
    .portrait {
      width: 6%;
      justify-content: flex-start;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
      }
    }
    .intR {
      width: 94%;
      justify-content: flex-end;
      .tR01 {
        width: 100%;
        height: 40px;
        line-height: 40px;
        display: flex;
        .lf {
          width: 50%;
          color: #999999;
          text-align: left;
          justify-content: flex-start;
        }
        .rg {
          width: 50%;
          color: #999999;
          text-align: right;
          justify-content: flex-end;
        }
      }
      .tR02 {
        width: 100%;
      }
      .tR03 {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        div {
          vertical-align: middle;
          img {
            margin-right: 6px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .Lintlist {
    width: 94%;
    margin: auto 1% auto auto;
    padding: 10px 0;
    display: flex;
    float: right;
    border-bottom: 1px solid #AFE6FF;
    .portrait {
      width: 6%;
      justify-content: flex-start;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
      }
    }
    .intR {
      width: 94%;
      justify-content: flex-end;
      .tR01 {
        width: 100%;
        height: 40px;
        line-height: 40px;
        display: flex;
        .lf {
          width: 50%;
          color: #999999;
          text-align: left;
          justify-content: flex-start;
        }
        .rg {
          width: 50%;
          color: #999999;
          text-align: right;
          justify-content: flex-end;
        }
      }
      .tR02 {
        width: 100%;
      }
      .tR03 {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        div {
          vertical-align: middle;
          img {
            margin-right: 6px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .AnswerSheet {
    width: 96%;
    overflow-y: auto;
    margin: auto;
    overflow-x: hidden;

    .Acontent {
      width: 100%;
      padding: 0px 20px 0px 0px;
      .Aname {
        line-height: 40px;
        font-size: 14px;
        font-weight: bold;
      }
      .Acn {
        padding:  0 6px 10px 0px;
      }
      .Alist {
        width: 100%;
        line-height: 30px;
        position: relative;
        display: flex;
        .A1 {
          width: 40px;
          float: left;
        }
        .A2 {
          width: 100%;
          float: left;
          padding-left: 50px;
          box-sizing: border-box;
          /*选项*/
          .Aoption {
            width: 100%;
            display: flex;
            .A-tem {
              width: 60px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border: 1px solid #707070;
              margin: 0 10px;
            }
          }
          /*非选项*/
          .Foption {
            width: 100%;
            box-sizing: border-box;
          }
        }
      }
    }

  }
  .active{
    border:3px solid #FF9100;
  }

  //.pic-item{
  //  width:230px;
  //  height: 98px;
  //  background-color: red;
  //  margin-bottom: 10px;
  //}
  .pic-item{
    width:230px;
    height: 104px;
    margin-bottom: 6px;
    position:relative;
    z-index:1;

  }
  .pic-item img{
    height: 104px;
    position:absolute;
    margin: 0px auto;
    left:0;
    right:0;
    width:100%;
    z-index: -1;
    *zoom:1;
  }
  .pic-item:before {
    content: "";
    display: inline-block;
    padding-bottom: 100%;
    width: 0.1px; /*必须要有数值，否则无法把高度撑起来*/
    vertical-align: middle;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 1;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #999;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #999;
  }

</style>
