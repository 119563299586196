import { render, staticRenderFns } from "./UnitTestQuestions.vue?vue&type=template&id=533a4853&scoped=true&"
import script from "./UnitTestQuestions.vue?vue&type=script&lang=js&"
export * from "./UnitTestQuestions.vue?vue&type=script&lang=js&"
import style0 from "./UnitTestQuestions.vue?vue&type=style&index=0&id=533a4853&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533a4853",
  null
  
)

export default component.exports